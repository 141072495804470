/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ar', name: 'Arabic', direction: 'rtl' },
];
// const firebaseConfigQA = {
//   apiKey: '',
//   authDomain: 'supply-qa.firebaseapp.com',
//   projectId: 'supply-qa',
//   storageBucket: 'supply-qa.appspot.com',
//   messagingSenderId: '428145837378',
//   appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
// };
// const firebaseConfigQA = {
//   apiKey: '',
//   authDomain: 'searchvm-dev.firebaseapp.com',
//   databaseURL: 'https://searchvm-dev.firebaseio.com',
//   projectId: 'searchvm-dev',
//   storageBucket: 'searchvm-dev.appspot.com',
//   messagingSenderId: '679987494918',
//   appId: '1:679987494918:web:79f0c7ed8b4afc2f368387',
// };
// const firebaseConfigDev = {
//   apiKey: '',
//   authDomain: 'searchvm-dev.firebaseapp.com',
//   databaseURL: 'https://searchvm-dev.firebaseio.com',
//   projectId: 'searchvm-dev',
//   storageBucket: 'searchvm-dev.appspot.com',
//   messagingSenderId: '679987494918',
//   appId: '1:679987494918:web:79f0c7ed8b4afc2f368387',
// };

export const firebaseConfig = JSON.parse(process.env.STYLI_APP_FIREBASE_INIT);
export const firebase1Config = JSON.parse(process.env.STYLI_APP_FIREBASE1_INIT);
export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: 0,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = process.env.MULIN_API_HOST;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.bluenavy';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const stores = {
  1: { name: 'KSA English', countryCode: 'sa', langCode: 'en' },
  3: { name: 'KSA Arabic', countryCode: 'sa', langCode: 'ar' },
  7: { name: 'UAE English', countryCode: 'ae', langCode: 'en' },
  11: { name: 'UAE Arabic', countryCode: 'ae', langCode: 'ar' },
  12: { name: 'KWT English', countryCode: 'kw', langCode: 'en' },
  13: { name: 'KWT Arabic', countryCode: 'kw', langCode: 'ar' },
  15: { name: 'QAT English', countryCode: 'qa', langCode: 'en' },
  17: { name: 'QAT Arabic', countryCode: 'qa', langCode: 'ar' },
  19: { name: 'BAH English', countryCode: 'bh', langCode: 'en' },
  21: { name: 'BAH Arabic', countryCode: 'bh', langCode: 'ar' },
  51: { name: 'IND English', countryCode: 'in', langCode: 'en' },
};

export const rowModel = {
  "data": {
    "contentType": {
      "uid": "application::rows.rows",
      "apiID": "rows",
      "schema": {
        "modelType": "contentType",
        "connection": "default",
        "collectionName": "rows",
        "info": {
          "name": "Rows"
        },
        "options": {
          "increments": true,
          "timestamps": ["createdAt", "updatedAt"]
        },
        "attributes": {
          "id": {
            "type": "string"
          },
          "Name": {
            "type": "string"
          },
          "Categories": {
            "type": "enumeration",
            "enum": ["Women", "Men", "Beauty", "Kids", "Common"]
          },
          "Rows": {
            "type": "component",
            "repeatable": false,
            "component": "grid.rows"
          },
          "visibleInPLP": {
            "type": "boolean"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "updatedAt": {
            "type": "timestamp"
          }
        }
      },
      "settings": {
        "bulkable": true,
        "filterable": true,
        "searchable": true,
        "pageSize": 50,
        "mainField": "Name",
        "defaultSortBy": "updatedAt",
        "defaultSortOrder": "DESC"
      },
      "metadatas": {
        "id": {
          "edit": {},
          "list": {
            "label": "Id",
            "searchable": true,
            "sortable": true
          }
        },
        "Name": {
          "edit": {
            "label": "Name",
            "description": "",
            "placeholder": "",
            "visible": true,
            "editable": true
          },
          "list": {
            "label": "Name",
            "searchable": true,
            "sortable": true
          }
        },
        "Categories": {
          "edit": {
            "label": "Categories",
            "description": "",
            "placeholder": "",
            "visible": true,
            "editable": true
          },
          "list": {
            "label": "Categories",
            "searchable": true,
            "sortable": true
          }
        },
        "Rows": {
          "edit": {
            "label": "Rows",
            "description": "",
            "placeholder": "",
            "visible": true,
            "editable": true
          },
          "list": {
            "label": "Rows",
            "searchable": false,
            "sortable": false
          }
        },
        "visibleInPLP": {
          "edit": {
            "label": "VisibleInPLP",
            "description": "",
            "placeholder": "",
            "visible": true,
            "editable": true
          },
          "list": {
            "label": "VisibleInPLP",
            "searchable": true,
            "sortable": true
          }
        },
        "createdAt": {
          "edit": {
            "label": "CreatedAt",
            "description": "",
            "placeholder": "",
            "visible": false,
            "editable": true
          },
          "list": {
            "label": "CreatedAt",
            "searchable": true,
            "sortable": true
          }
        },
        "updatedAt": {
          "edit": {
            "label": "UpdatedAt",
            "description": "",
            "placeholder": "",
            "visible": false,
            "editable": true
          },
          "list": {
            "label": "UpdatedAt",
            "searchable": true,
            "sortable": true
          }
        }
      },
      "layouts": {
        "list": ["Name", "Categories", "createdAt", "updatedAt"],
        "edit": [
          [
            {
              "name": "Name",
              "size": 6
            },
            {
              "name": "Categories",
              "size": 6
            }
          ],
          [
            {
              "name": "visibleInPLP",
              "size": 4
            }
          ],
          [
            {
              "name": "Rows",
              "size": 12
            }
          ]
        ],
        "editRelations": []
      }
    },
    "components": {
      "grid.rows": {
        "uid": "grid.rows",
        "category": "grid",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_grid_rows",
          "info": {
            "name": "rows",
            "icon": "align-justify"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "rowType": {
              "type": "enumeration",
              "enum": [
                "Grid",
                "BannerCarousel",
                "CategoryCarousel",
                "ProductGrid",
                "FocusedCarousel",
                "ProductCarousel",
                "ImageCarousel",
                "RecomRecentlyViewed",
                "VideoGrid",
                "MultiTabProducts",
                "Recommendation",
                "ProductTile",
                "BannerGridCarousel"
              ]
            },
            "status": {
              "type": "enumeration",
              "enum": ["Draft", "Published"]
            },
            "scheduleInfo": {
              "type": "component",
              "repeatable": false,
              "component": "scheduled-info.status"
            },
            "productGrid": {
              "type": "component",
              "repeatable": false,
              "component": "carousel.product-carousel"
            },
            "cloumnStyling": {
              "type": "component",
              "repeatable": false,
              "component": "grid.styling"
            },
            "bottomSeparator": {
              "type": "boolean",
              "default": false
            },
            "layoutCount": {
              "type": "component",
              "repeatable": false,
              "component": "grid.column-count",
              "required": true
            },
            "viewMore": {
              "type": "component",
              "repeatable": false,
              "component": "grid.view-more"
            },
            "rowTitle": {
              "type": "string"
            },
            "widgets": {
              "type": "component",
              "repeatable": true,
              "component": "widget.widget-columns"
            },
            "analytics": {
              "type": "component",
              "repeatable": false,
              "component": "grid.analytics",
              "required": true
            },
            "countries": {
              "collection": "countries",
              "type": "relation",
              "targetModel": "application::countries.countries",
              "relationType": "manyWay"
            },
            "slideShowTime": {
              "type": "integer",
              "min": 0
            },
            "showItemCount": {
              "type": "boolean"
            },
            "selectedTabBackgroundColor": {
              "type": "string"
            },
            "selectedTabTextColor": {
              "type": "string"
            },
            "selectedTabBorderColor": {
              "type": "string"
            },
            "nonSelectedTabBorderColor": {
              "type": "string"
            },
            "SaleTimer": {
              "type": "component",
              "repeatable": false,
              "component": "widget.sale-timer"
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "name",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "name": {
            "edit": {
              "label": "Name*",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Name",
              "searchable": true,
              "sortable": true
            }
          },
          "rowType": {
            "edit": {
              "label": "RowType*",
              "description": "Type of container we wish to create",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "RowType",
              "searchable": true,
              "sortable": true
            }
          },
          "status": {
            "edit": {
              "label": "Status*",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Status",
              "searchable": true,
              "sortable": true
            }
          },
          "scheduleInfo": {
            "edit": {
              "label": "ScheduleInfo",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ScheduleInfo",
              "searchable": false,
              "sortable": false
            }
          },
          "productGrid": {
            "edit": {
              "label": "ProductGrid",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ProductGrid",
              "searchable": false,
              "sortable": false
            }
          },
          "cloumnStyling": {
            "edit": {
              "label": "CloumnStyling",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "CloumnStyling",
              "searchable": false,
              "sortable": false
            }
          },
          "bottomSeparator": {
            "edit": {
              "label": "BottomSeparator",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "BottomSeparator",
              "searchable": true,
              "sortable": true
            }
          },
          "layoutCount": {
            "edit": {
              "label": "LayoutCount",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "LayoutCount",
              "searchable": false,
              "sortable": false
            }
          },
          "viewMore": {
            "edit": {
              "label": "ViewMore",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ViewMore",
              "searchable": false,
              "sortable": false
            }
          },
          "rowTitle": {
            "edit": {
              "label": "RowTitle",
              "description": "Insert a heading or title on top of the row\t",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "RowTitle",
              "searchable": true,
              "sortable": true
            }
          },
          "widgets": {
            "edit": {
              "label": "Widgets",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Widgets",
              "searchable": false,
              "sortable": false
            }
          },
          "analytics": {
            "edit": {
              "label": "Analytics",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Analytics",
              "searchable": false,
              "sortable": false
            }
          },
          "countries": {
            "edit": {
              "label": "Countries",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true,
              "mainField": "Country"
            },
            "list": {
              "label": "Countries",
              "searchable": false,
              "sortable": false
            }
          },
          "slideShowTime": {
            "edit": {
              "label": "SlideShowTime",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SlideShowTime",
              "searchable": true,
              "sortable": true
            }
          },
          "showItemCount": {
            "edit": {
              "label": "ShowItemCount",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ShowItemCount",
              "searchable": true,
              "sortable": true
            }
          },
          "selectedTabBackgroundColor": {
            "edit": {
              "label": "SelectedTabBackgroundColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SelectedTabBackgroundColor",
              "searchable": true,
              "sortable": true
            }
          },
          "selectedTabTextColor": {
            "edit": {
              "label": "SelectedTabTextColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SelectedTabTextColor",
              "searchable": true,
              "sortable": true
            }
          },
          "selectedTabBorderColor": {
            "edit": {
              "label": "SelectedTabBorderColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SelectedTabBorderColor",
              "searchable": true,
              "sortable": true
            }
          },
          "nonSelectedTabBorderColor": {
            "edit": {
              "label": "NonSelectedTabBorderColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "NonSelectedTabBorderColor",
              "searchable": true,
              "sortable": true
            }
          },
          "SaleTimer": {
            "edit": {
              "label": "SaleTimer",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SaleTimer",
              "searchable": false,
              "sortable": false
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "name", "rowType", "slideShowTime"],
          "edit": [
            [
              {
                "name": "name",
                "size": 6
              },
              {
                "name": "rowType",
                "size": 6
              }
            ],
            [
              {
                "name": "rowTitle",
                "size": 6
              },
              {
                "name": "status",
                "size": 6
              }
            ],
            [
              {
                "name": "analytics",
                "size": 12
              }
            ],
            [
              {
                "name": "scheduleInfo",
                "size": 12
              }
            ],
            [
              {
                "name": "viewMore",
                "size": 12
              }
            ],
            [
              {
                "name": "layoutCount",
                "size": 12
              }
            ],
            [
              {
                "name": "productGrid",
                "size": 12
              }
            ],
            [
              {
                "name": "cloumnStyling",
                "size": 12
              }
            ],
            [
              {
                "name": "bottomSeparator",
                "size": 4
              }
            ],
            [
              {
                "name": "widgets",
                "size": 12
              }
            ],
            [
              {
                "name": "countries",
                "size": 6
              },
              {
                "name": "slideShowTime",
                "size": 4
              }
            ],
            [
              {
                "name": "SaleTimer",
                "size": 12
              }
            ],
            [
              {
                "name": "showItemCount",
                "size": 4
              },
              {
                "name": "selectedTabBackgroundColor",
                "size": 6
              }
            ],
            [
              {
                "name": "selectedTabTextColor",
                "size": 6
              },
              {
                "name": "selectedTabBorderColor",
                "size": 6
              }
            ],
            [
              {
                "name": "nonSelectedTabBorderColor",
                "size": 6
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "scheduled-info.status": {
        "uid": "scheduled-info.status",
        "category": "scheduled-info",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_status_info_statuses",
          "info": {
            "name": "Scheduled",
            "icon": "angry"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "scheduledFrom": {
              "type": "datetime"
            },
            "scheduledTill": {
              "type": "datetime"
            },
            "countDown": {
              "type": "boolean"
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "id",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "scheduledFrom": {
            "edit": {
              "label": "ScheduledFrom",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ScheduledFrom",
              "searchable": true,
              "sortable": true
            }
          },
          "scheduledTill": {
            "edit": {
              "label": "ScheduledTill",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ScheduledTill",
              "searchable": true,
              "sortable": true
            }
          },
          "countDown": {
            "edit": {
              "label": "CountDown",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "CountDown",
              "searchable": true,
              "sortable": true
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "scheduledFrom", "scheduledTill", "createdAt"],
          "edit": [
            [
              {
                "name": "scheduledFrom",
                "size": 6
              },
              {
                "name": "scheduledTill",
                "size": 6
              }
            ],
            [
              {
                "name": "countDown",
                "size": 4
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "carousel.product-carousel": {
        "uid": "carousel.product-carousel",
        "category": "carousel",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_carousel_product_carousels",
          "info": {
            "name": "ProductCarousel",
            "icon": "th"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "listOfProductSKUs": {
              "type": "text"
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "id",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "listOfProductSKUs": {
            "edit": {
              "label": "ListOfProductSKUs",
              "description": "Max # of SKUs : 60 only.  Format : curofy/contextid/10 for direct SKUs from context",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ListOfProductSKUs",
              "searchable": true,
              "sortable": true
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "listOfProductSKUs"],
          "edit": [
            [
              {
                "name": "listOfProductSKUs",
                "size": 6
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "grid.styling": {
        "uid": "grid.styling",
        "category": "grid",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_grid_stylings",
          "info": {
            "name": "styling",
            "icon": "align-center"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "spacing": {
              "type": "integer",
              "default": 4
            },
            "mainTitleFontSize": {
              "type": "integer"
            },
            "subTextFontSize": {
              "type": "integer"
            },
            "mainTitleColor": {
              "type": "string"
            },
            "subTitleColor": {
              "type": "string"
            },
            "mainTitleBold": {
              "type": "boolean",
              "default": true
            },
            "subTextBold": {
              "type": "boolean",
              "default": false
            },
            "horizontalSpacing": {
              "type": "integer"
            },
            "bottomSpacing": {
              "type": "integer"
            },
            "backgroundColor": {
              "type": "string"
            },
            "aspectRatio": {
              "type": "enumeration",
              "enum": ["AR_16x9", "AR_4x3", "AR_3x2", "AR_1x1"]
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "id",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "spacing": {
            "edit": {
              "label": "Spacing",
              "description": "Column Spacing between widgets. Will not impact left most and right most padding",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Spacing",
              "searchable": true,
              "sortable": true
            }
          },
          "mainTitleFontSize": {
            "edit": {
              "label": "MainTitleFontSize",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "MainTitleFontSize",
              "searchable": true,
              "sortable": true
            }
          },
          "subTextFontSize": {
            "edit": {
              "label": "SubTextFontSize",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SubTextFontSize",
              "searchable": true,
              "sortable": true
            }
          },
          "mainTitleColor": {
            "edit": {
              "label": "MainTitleColor",
              "description": "Value in Hex Code",
              "placeholder": "Eg #AABBCC",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "MainTitleColor",
              "searchable": true,
              "sortable": true
            }
          },
          "subTitleColor": {
            "edit": {
              "label": "SubTitleColor",
              "description": "Value in HexCode",
              "placeholder": "#AABBCC",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SubTitleColor",
              "searchable": true,
              "sortable": true
            }
          },
          "mainTitleBold": {
            "edit": {
              "label": "MainTitleBold",
              "description": "Typically set as YES",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "MainTitleBold",
              "searchable": true,
              "sortable": true
            }
          },
          "subTextBold": {
            "edit": {
              "label": "SubTextBold",
              "description": "Typically set as NO",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SubTextBold",
              "searchable": true,
              "sortable": true
            }
          },
          "horizontalSpacing": {
            "edit": {
              "label": "HorizontalSpacing",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "HorizontalSpacing",
              "searchable": true,
              "sortable": true
            }
          },
          "bottomSpacing": {
            "edit": {
              "label": "BottomSpacing",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "BottomSpacing",
              "searchable": true,
              "sortable": true
            }
          },
          "backgroundColor": {
            "edit": {
              "label": "BackgroundColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "BackgroundColor",
              "searchable": true,
              "sortable": true
            }
          },
          "aspectRatio": {
            "edit": {
              "label": "AspectRatio",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "AspectRatio",
              "searchable": true,
              "sortable": true
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "spacing", "mainTitleFontSize", "subTextFontSize"],
          "edit": [
            [
              {
                "name": "spacing",
                "size": 4
              },
              {
                "name": "mainTitleFontSize",
                "size": 4
              },
              {
                "name": "subTextFontSize",
                "size": 4
              }
            ],
            [
              {
                "name": "mainTitleColor",
                "size": 6
              },
              {
                "name": "subTitleColor",
                "size": 6
              }
            ],
            [
              {
                "name": "mainTitleBold",
                "size": 4
              },
              {
                "name": "subTextBold",
                "size": 4
              },
              {
                "name": "horizontalSpacing",
                "size": 4
              }
            ],
            [
              {
                "name": "bottomSpacing",
                "size": 4
              },
              {
                "name": "backgroundColor",
                "size": 6
              }
            ],
            [
              {
                "name": "aspectRatio",
                "size": 6
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "grid.column-count": {
        "uid": "grid.column-count",
        "category": "grid",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_grid_column_counts",
          "info": {
            "name": "columnCount",
            "icon": "archway"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "mobile": {
              "type": "integer",
              "default": 2,
              "max": 12,
              "min": 1,
              "required": true
            },
            "tab": {
              "type": "integer",
              "default": 4,
              "required": true,
              "max": 12,
              "min": 1
            },
            "desktop": {
              "type": "integer",
              "default": 4,
              "max": 12,
              "min": 1
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "id",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "mobile": {
            "edit": {
              "label": "Mobile*",
              "description": "Columns in Mobile Devices",
              "placeholder": "Eg 2",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Mobile",
              "searchable": true,
              "sortable": true
            }
          },
          "tab": {
            "edit": {
              "label": "Tab*",
              "description": "Columns in Tablet Devices",
              "placeholder": "Eg 3",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Tab",
              "searchable": true,
              "sortable": true
            }
          },
          "desktop": {
            "edit": {
              "label": "Desktop*",
              "description": "Columns in Desktop",
              "placeholder": "Eg 4",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Desktop",
              "searchable": true,
              "sortable": true
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "mobile", "tab", "desktop"],
          "edit": [
            [
              {
                "name": "mobile",
                "size": 4
              },
              {
                "name": "tab",
                "size": 4
              },
              {
                "name": "desktop",
                "size": 4
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "grid.view-more": {
        "uid": "grid.view-more",
        "category": "grid",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_grid_view_mores",
          "info": {
            "name": "viewMore",
            "icon": "allergies"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "link": {
              "type": "string"
            },
            "text": {
              "type": "string"
            },
            "viewId": {
              "type": "integer"
            },
            "target": {
              "type": "enumeration",
              "enum": ["Product", "Category"]
            },
            "targetTitle": {
              "type": "string"
            },
            "ContextName": {
              "type": "string"
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "id",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "link": {
            "edit": {
              "label": "Link",
              "description": "Eg: list/women/clothing/tops",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Link",
              "searchable": true,
              "sortable": true
            }
          },
          "text": {
            "edit": {
              "label": "Text",
              "description": "Text for Call to Action",
              "placeholder": "Eg. View More",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Text",
              "searchable": true,
              "sortable": true
            }
          },
          "viewId": {
            "edit": {
              "label": "ViewId",
              "description": "Category ID or Magento Product ID (Not SOD)",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ViewId",
              "searchable": true,
              "sortable": true
            }
          },
          "target": {
            "edit": {
              "label": "Target",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Target",
              "searchable": true,
              "sortable": true
            }
          },
          "targetTitle": {
            "edit": {
              "label": "TargetTitle",
              "description": "Page heading name for target URL",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TargetTitle",
              "searchable": true,
              "sortable": true
            }
          },
          "ContextName": {
            "edit": {
              "label": "ContextName",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ContextName",
              "searchable": true,
              "sortable": true
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "link", "text", "viewId"],
          "edit": [
            [
              {
                "name": "link",
                "size": 6
              },
              {
                "name": "text",
                "size": 6
              }
            ],
            [
              {
                "name": "target",
                "size": 6
              },
              {
                "name": "viewId",
                "size": 4
              }
            ],
            [
              {
                "name": "targetTitle",
                "size": 6
              },
              {
                "name": "ContextName",
                "size": 6
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "widget.widget-columns": {
        "uid": "widget.widget-columns",
        "category": "widget",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_grid_widget_columns",
          "info": {
            "name": "widgetColumns",
            "icon": "arrow-circle-up"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string",
              "required": false
            },
            "link": {
              "type": "string"
            },
            "itemId": {
              "type": "integer"
            },
            "widgetType": {
              "type": "enumeration",
              "enum": [
                "ImageTile",
                "IconTile",
                "FreeShipping",
                "Video",
                "UploadedVideo"
              ]
            },
            "subTitle": {
              "type": "string"
            },
            "backgroundColor": {
              "type": "string"
            },
            "target": {
              "type": "enumeration",
              "enum": [
                "Product",
                "Category",
                "Landing",
                "Referral",
                "UpdateGender",
                "App_Deeplink"
              ]
            },
            "imageMedia": {
              "type": "media",
              "multiple": true,
              "required": false
            },
            "videoUrl": {
              "type": "string"
            },
            "videoEmbed": {
              "type": "text"
            },
            "name": {
              "type": "string",
              "required": true
            },
            "targetTitle": {
              "type": "string"
            },
            "contextName": {
              "type": "string"
            },
            "contextSource": {
              "type": "enumeration",
              "enum": ["Curofy"]
            },
            "imageMediaDesktop": {
              "type": "media",
              "multiple": true,
              "required": false,
              "allowedTypes": ["images"]
            },
            "listOfProductsSKUs": {
              "type": "text"
            },
            "titleTextColor": {
              "type": "string"
            },
            "videos": {
              "type": "media",
              "multiple": true,
              "required": false
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "name",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "title": {
            "edit": {
              "label": "Title",
              "description": "Optional Title to be shown below the widget",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Title",
              "searchable": true,
              "sortable": true
            }
          },
          "link": {
            "edit": {
              "label": "Link",
              "description": "Eg: list/women/clothing/tops",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Link",
              "searchable": true,
              "sortable": true
            }
          },
          "itemId": {
            "edit": {
              "label": "ItemId",
              "description": "Category ID or Magento Product ID (Not SOD)",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ItemId",
              "searchable": true,
              "sortable": true
            }
          },
          "widgetType": {
            "edit": {
              "label": "WidgetType*",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "WidgetType",
              "searchable": true,
              "sortable": true
            }
          },
          "subTitle": {
            "edit": {
              "label": "SubTitle",
              "description": "Optional SubTitle to be shown below the widget",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "SubTitle",
              "searchable": true,
              "sortable": true
            }
          },
          "backgroundColor": {
            "edit": {
              "label": "BackgroundColor",
              "description": "Color should be in HEX code",
              "placeholder": "Eg #AABBCC",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "BackgroundColor",
              "searchable": true,
              "sortable": true
            }
          },
          "target": {
            "edit": {
              "label": "Target",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Target",
              "searchable": true,
              "sortable": true
            }
          },
          "imageMedia": {
            "edit": {
              "label": "Image* (MAX FILE SIZE: 300KB)",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ImageMedia",
              "searchable": false,
              "sortable": false
            }
          },
          "videoUrl": {
            "edit": {
              "label": "VideoUrl",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "VideoUrl",
              "searchable": true,
              "sortable": true
            }
          },
          "videoEmbed": {
            "edit": {
              "label": "VideoEmbed",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "VideoEmbed",
              "searchable": true,
              "sortable": true
            }
          },
          "name": {
            "edit": {
              "label": "Creative Name*",
              "description": "Also used for Analytics Tracking if you are running multiple variations",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Name",
              "searchable": true,
              "sortable": true
            }
          },
          "targetTitle": {
            "edit": {
              "label": "TargetTitle",
              "description": "Page heading name for target URL",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TargetTitle",
              "searchable": true,
              "sortable": true
            }
          },
          "contextName": {
            "edit": {
              "label": "ContextName",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ContextName",
              "searchable": true,
              "sortable": true
            }
          },
          "contextSource": {
            "edit": {
              "label": "ContextSource",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ContextSource",
              "searchable": true,
              "sortable": true
            }
          },
          "imageMediaDesktop": {
            "edit": {
              "label": "ImageMediaDesktop",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ImageMediaDesktop",
              "searchable": false,
              "sortable": false
            }
          },
          "listOfProductsSKUs": {
            "edit": {
              "label": "ListOfProductsSKUs",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ListOfProductsSKUs",
              "searchable": true,
              "sortable": true
            }
          },
          "titleTextColor": {
            "edit": {
              "label": "TitleTextColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TitleTextColor",
              "searchable": true,
              "sortable": true
            }
          },
          "videos": {
            "edit": {
              "label": "Videos",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Videos",
              "searchable": false,
              "sortable": false
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "title", "link", "itemId"],
          "edit": [
            [
              {
                "name": "name",
                "size": 6
              },
              {
                "name": "widgetType",
                "size": 6
              }
            ],
            [
              {
                "name": "title",
                "size": 6
              },
              {
                "name": "subTitle",
                "size": 6
              }
            ],
            [
              {
                "name": "target",
                "size": 6
              },
              {
                "name": "link",
                "size": 6
              }
            ],
            [
              {
                "name": "itemId",
                "size": 4
              },
              {
                "name": "backgroundColor",
                "size": 6
              }
            ],
            [
              {
                "name": "imageMedia",
                "size": 6
              },
              {
                "name": "videoEmbed",
                "size": 6
              }
            ],
            [
              {
                "name": "videoUrl",
                "size": 6
              },
              {
                "name": "targetTitle",
                "size": 6
              }
            ],
            [
              {
                "name": "contextName",
                "size": 6
              },
              {
                "name": "contextSource",
                "size": 6
              }
            ],
            [
              {
                "name": "imageMediaDesktop",
                "size": 6
              },
              {
                "name": "videos",
                "size": 6
              }
            ],
            [
              {
                "name": "listOfProductsSKUs",
                "size": 6
              },
              {
                "name": "titleTextColor",
                "size": 6
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "grid.analytics": {
        "uid": "grid.analytics",
        "category": "grid",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_grid_analytics",
          "info": {
            "name": "Analytics",
            "icon": "balance-scale-right"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "promoName": {
              "type": "string",
              "required": true
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "id",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "promoName": {
            "edit": {
              "label": "PromoName*",
              "description": "Used by Analytics to track your banner or promotion",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "PromoName",
              "searchable": true,
              "sortable": true
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "promoName", "createdAt"],
          "edit": [
            [
              {
                "name": "promoName",
                "size": 6
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      },
      "widget.sale-timer": {
        "uid": "widget.sale-timer",
        "category": "widget",
        "schema": {
          "modelType": "component",
          "connection": "default",
          "collectionName": "components_widget_sale_timers",
          "info": {
            "name": "SaleTimer",
            "icon": "history"
          },
          "options": {
            "timestamps": ["createdAt", "updatedAt"]
          },
          "attributes": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "startTime": {
              "type": "datetime"
            },
            "endTime": {
              "type": "datetime"
            },
            "titleColor": {
              "type": "string"
            },
            "titleBackground": {
              "type": "string"
            },
            "titleFontSize": {
              "type": "string"
            },
            "widgetBackgroundColor": {
              "type": "string"
            },
            "timerTileBackgroundColor": {
              "type": "string"
            },
            "timerTextColor": {
              "type": "string"
            },
            "timerFontSize": {
              "type": "string"
            },
            "timerLabelTextColor": {
              "type": "string"
            },
            "timerLabelFontSize": {
              "type": "string"
            },
            "timerColonColor": {
              "type": "string"
            },
            "target": {
              "type": "enumeration",
              "enum": [
                "Product",
                "Category",
                "Landing",
                "Referral",
                "UpdateGender",
                "App_Deeplink"
              ]
            },
            "link": {
              "type": "string"
            },
            "contextName": {
              "type": "string"
            },
            "landingID": {
              "type": "string"
            },
            "landingJsonUrl": {
              "type": "string"
            },
            "createdAt": {
              "type": "timestamp"
            },
            "updatedAt": {
              "type": "timestamp"
            }
          }
        },
        "settings": {
          "bulkable": true,
          "filterable": true,
          "searchable": true,
          "pageSize": 10,
          "mainField": "id",
          "defaultSortBy": "id",
          "defaultSortOrder": "ASC"
        },
        "metadatas": {
          "id": {
            "edit": {},
            "list": {
              "label": "Id",
              "searchable": false,
              "sortable": false
            }
          },
          "title": {
            "edit": {
              "label": "Title",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Title",
              "searchable": true,
              "sortable": true
            }
          },
          "startTime": {
            "edit": {
              "label": "StartTime",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "StartTime",
              "searchable": true,
              "sortable": true
            }
          },
          "endTime": {
            "edit": {
              "label": "EndTime",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "EndTime",
              "searchable": true,
              "sortable": true
            }
          },
          "titleColor": {
            "edit": {
              "label": "TitleColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TitleColor",
              "searchable": true,
              "sortable": true
            }
          },
          "titleBackground": {
            "edit": {
              "label": "TitleBackground",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TitleBackground",
              "searchable": true,
              "sortable": true
            }
          },
          "titleFontSize": {
            "edit": {
              "label": "TitleFontSize",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TitleFontSize",
              "searchable": true,
              "sortable": true
            }
          },
          "widgetBackgroundColor": {
            "edit": {
              "label": "WidgetBackgroundColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "WidgetBackgroundColor",
              "searchable": true,
              "sortable": true
            }
          },
          "timerTileBackgroundColor": {
            "edit": {
              "label": "TimerTileBackgroundColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TimerTileBackgroundColor",
              "searchable": true,
              "sortable": true
            }
          },
          "timerTextColor": {
            "edit": {
              "label": "TimerTextColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TimerTextColor",
              "searchable": true,
              "sortable": true
            }
          },
          "timerFontSize": {
            "edit": {
              "label": "TimerFontSize",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TimerFontSize",
              "searchable": true,
              "sortable": true
            }
          },
          "timerLabelTextColor": {
            "edit": {
              "label": "TimerLabelTextColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TimerLabelTextColor",
              "searchable": true,
              "sortable": true
            }
          },
          "timerLabelFontSize": {
            "edit": {
              "label": "TimerLabelFontSize",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TimerLabelFontSize",
              "searchable": true,
              "sortable": true
            }
          },
          "timerColonColor": {
            "edit": {
              "label": "TimerColonColor",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "TimerColonColor",
              "searchable": true,
              "sortable": true
            }
          },
          "target": {
            "edit": {
              "label": "Target",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Target",
              "searchable": true,
              "sortable": true
            }
          },
          "link": {
            "edit": {
              "label": "Link",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "Link",
              "searchable": true,
              "sortable": true
            }
          },
          "contextName": {
            "edit": {
              "label": "ContextName",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "ContextName",
              "searchable": true,
              "sortable": true
            }
          },
          "landingID": {
            "edit": {
              "label": "LandingID",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "LandingID",
              "searchable": true,
              "sortable": true
            }
          },
          "landingJsonUrl": {
            "edit": {
              "label": "LandingJsonUrl",
              "description": "",
              "placeholder": "",
              "visible": true,
              "editable": true
            },
            "list": {
              "label": "LandingJsonUrl",
              "searchable": true,
              "sortable": true
            }
          },
          "createdAt": {
            "edit": {
              "label": "CreatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "CreatedAt",
              "searchable": true,
              "sortable": true
            }
          },
          "updatedAt": {
            "edit": {
              "label": "UpdatedAt",
              "description": "",
              "placeholder": "",
              "visible": false,
              "editable": true
            },
            "list": {
              "label": "UpdatedAt",
              "searchable": true,
              "sortable": true
            }
          }
        },
        "layouts": {
          "list": ["id", "title", "startTime", "endTime"],
          "edit": [
            [
              {
                "name": "title",
                "size": 6
              },
              {
                "name": "startTime",
                "size": 6
              }
            ],
            [
              {
                "name": "endTime",
                "size": 6
              },
              {
                "name": "titleColor",
                "size": 6
              }
            ],
            [
              {
                "name": "titleBackground",
                "size": 6
              },
              {
                "name": "titleFontSize",
                "size": 6
              }
            ],
            [
              {
                "name": "widgetBackgroundColor",
                "size": 6
              },
              {
                "name": "timerTileBackgroundColor",
                "size": 6
              }
            ],
            [
              {
                "name": "timerTextColor",
                "size": 6
              },
              {
                "name": "timerFontSize",
                "size": 6
              }
            ],
            [
              {
                "name": "timerLabelTextColor",
                "size": 6
              },
              {
                "name": "timerLabelFontSize",
                "size": 6
              }
            ],
            [
              {
                "name": "timerColonColor",
                "size": 6
              },
              {
                "name": "target",
                "size": 6
              }
            ],
            [
              {
                "name": "link",
                "size": 6
              },
              {
                "name": "contextName",
                "size": 6
              }
            ],
            [
              {
                "name": "landingID",
                "size": 6
              },
              {
                "name": "landingJsonUrl",
                "size": 6
              }
            ]
          ],
          "editRelations": []
        },
        "isComponent": true
      }
    }
  }
}
